import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import { translations } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify/utils";

//　Amplify UIの日本語化対応
const customTranslations = {
  ja: {
    "Incorrect username or password.":
      "ユーザー名またはパスワードが間違っています",
    "Enter your email": "メールアドレスを入力",
    "Code *": "認証コード",
    "Password must have at least 8 characters": "パスワードは8文字以上必要です",
    "Your passwords must match": "パスワードが一致しません",
    "Invalid verification code provided, please try again.":
      "無効な認証コードが入力されました",
  },
};

I18n.putVocabularies(translations);
I18n.putVocabularies(customTranslations);
I18n.setLanguage("ja");

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_AWC_COGNIT_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_AWC_COGNIT_CLIENT_ID,
      loginWith: {
        email: true,
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
