/** ボタンのコンポーネント */
const Button = (props) => {
  return (
    <button
      onClick={props.event}
      disabled={props.disabled}
      className={props.className || ""}
    >
      {props.name}
    </button>
  );
};

export default Button;
