import "./App.css";
import { useState, useEffect } from "react";
import Button from "./components/Button/Button";
import { getAlertData, updateAlertData } from "./dataService";
import UploadModal from "./components/Modal/UploadModal";
import ErrorModal from "./components/Modal/ErrorModal";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

/** 検索条件：警報ランクのセレクトボックスのデータ */
const alertRank = [
  { label: "A", value: "A" },
  { label: "B", value: "B" },
  { label: "C", value: "C" },
  { label: "ランク外", value: "Z" },
];

/** 検索条件：営業所のセレクトボックスのデータ */
const office = [
  { label: "指定なし", value: "" },
  { label: "富山", value: "富山" },
  { label: "高岡", value: "高岡" },
  { label: "新川", value: "新川" },
  { label: "金沢", value: "金沢" },
  { label: "七尾", value: "七尾" },
  { label: "小松", value: "小松" },
  { label: "福井", value: "福井" },
  { label: "丹南", value: "丹南" },
  { label: "本店", value: "本店" },
  { label: "石川", value: "石川" },
  { label: "不明", value: "不明" },
];

function App() {
  const [items, setItems] = useState([]);
  const [statuses, setStatuses] = useState([
    { checked: true, value: "未対応" },
    { checked: true, value: "対応中" },
    { checked: true, value: "経過観察中" },
    { checked: false, value: "完了" },
  ]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [error, setError] = useState(null);

  /** 初期表示時の検索条件 */
  const defaultParams = {
    alert_rank: "A",
    mail_received_date: "",
    office: "",
    building: "",
    status: statuses
      .filter((status) => status.checked)
      .map((status) => status.value),
  };
  const [searchParams, setSearchParams] = useState(defaultParams);

  // 初期レンダリング時にデータを表示
  useEffect(() => {
    const getData = async () => {
      try {
        const data = await getAlertData(searchParams);
        setItems(data);
      } catch (error) {
        setError(error);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    if (error) {
      setShowErrorModal(true);
    }
  }, [error]);

  /** 検索条件設定時の処理 */
  const handleChange = (event) => {
    let { name, value, type, checked } = event.target;
    // YYYY-MM-DD形式をYYYY/MM/DD形式に変換する
    const dateFormatChange = (date) => {
      return date.replace(/-/g, "/");
    };
    // 対応するステータスを更新する
    const onCheckStatus = statuses.map((status) => {
      if (status.value === value) {
        // 現状のstatusを展開して変更箇所のみ更新する
        return { ...status, checked: checked };
      }
      return status;
    });

    switch (type) {
      case "date":
        value = dateFormatChange(value);
        setSearchParams((prev) => ({
          ...prev,
          [name]: value,
        }));
        break;
      case "checkbox":
        setSearchParams((prev) => ({
          ...prev,
          status: onCheckStatus
            .filter((status) => status.checked)
            .map((status) => status.value),
        }));
        setStatuses(onCheckStatus);
        break;
      default:
        setSearchParams((prev) => ({
          ...prev,
          [name]: value,
        }));
    }
  };

  /** 警報データの対応状況を替えたときの処理 */
  const handleStatusChange = (index, newStatus) => {
    const updatedItems = [...items];
    updatedItems[index].status = newStatus;
    setItems(updatedItems);
  };

  /** 検索ボタン押下時の処理 */
  const handleSearch = async () => {
    try {
      const data = await getAlertData(searchParams);
      setItems(data);
    } catch (error) {
      setError(error);
    }
  };

  /** 更新ボタン押下時の処理 */
  const handleUpdateStatus = async (index, item) => {
    try {
      const newStatus = items[index].status;
      await updateAlertData(item, newStatus);
    } catch (error) {
      setError(error);
    }
  };

  /** 警報リストのアップロードモーダルを表示 */
  const ShowModal = () => {
    setShowUploadModal(true);
  };

  return (
    <Authenticator hideSignUp={true}>
      <div className="flex flex-col items-center p-6 h-full overflow-y-auto">
        <button
          onClick={ShowModal}
          className="btn btn-sm sm:btn-sm md:btn-md lg:btn-lg btn-outline btn-primary ml-auto"
        >
          警報リスト
          <br />
          アップロード
        </button>
        <UploadModal open={showUploadModal} setOpen={setShowUploadModal} />
        <div>
          <h2 className="text-4xl font-bold text-left py-2">警報データ</h2>
        </div>
        <div className="grid grid-col gap-2 p-4 shadow-md">
          <div className="flex flex-wrap items-center justify-between gap-2 row-auto">
            <span className="text-lg my-2 mr-2">営業所:</span>
            <select
              name="office"
              value={searchParams.office}
              onChange={handleChange}
              className="select select-bordered w-[50%]"
            >
              {office.map((item) => (
                <option value={item.value}>{item.label}</option>
              ))}
            </select>
          </div>
          <div className="flex items-center justify-between gap-2">
            <span className="text-lg my-2 mr-2">物件名:</span>
            <input
              type="text"
              name="building"
              onChange={handleChange}
              className="input input-bordered w-[50%]"
            ></input>
          </div>
          <div className="flex items-center justify-between gap-2">
            <span className="text-lg my-2 mr-2">警報ランク:</span>
            <select
              name="alert_rank"
              value={searchParams.alert_rank}
              onChange={handleChange}
              className="select select-bordered w-[50%]"
            >
              {alertRank.map((item) => (
                <option value={item.value}>{item.label}</option>
              ))}
            </select>
          </div>
          <div className="flex flex-wrap items-center justify-between">
            <span className="text-lg flex my-2 mr-2">対応状況:</span>
            <div className="flex flex-wrap">
              {statuses.map((status) => (
                <div key={status.value} className="flex items-center mx-1 my-1">
                  <div>{status.value}</div>
                  <input
                    type="checkbox"
                    name="status"
                    value={status.value}
                    checked={status.checked}
                    onChange={handleChange}
                    className="checkbox checkbox-primary checkbox-md mx-2"
                  ></input>
                </div>
              ))}
            </div>
          </div>
          <div className="flex items-center justify-between gap-2">
            <span className="text-lg my-2 mr-2">メール受信日:</span>
            <input
              type="date"
              name="mail_received_date"
              onChange={handleChange}
              className="input input-bordered w-[50%]"
            ></input>
          </div>
          <div className="flex items-center justify-end">
            <Button
              name={"検索"}
              event={handleSearch}
              className="btn btn-info"
            ></Button>
          </div>
        </div>
        <h2 className="text-2xl my-2">警報メール</h2>
        {items.length ? (
          items.map((item, index) => (
            <div className="grid grid-cols-2 gap-2 p-4 shadow-md" key={index}>
              <span className="font-bold text-lg mr-2">警報ランク:</span>
              <div className="text-lg mr-2">
                {item.alert_rank === "Z" ? "ランク外" : item.alert_rank}
              </div>
              <span className="font-bold text-lg mr-2">メール受信日:</span>
              <div className="text-lg mr-2">{item.mail_received_date}</div>
              <span className="font-bold text-lg mr-2">営業所:</span>
              <div className="text-lg mr-2">{item.office}</div>
              <span className="font-bold text-lg mr-2">物件名:</span>
              <div className="text-lg mr-2">{item.building}</div>
              <span className="font-bold text-lg mr-2">対応状況:</span>
              <div className="flex flex-wrap items-center gap-2">
                <select
                  value={item.status}
                  onChange={(e) => handleStatusChange(index, e.target.value)}
                  disabled={item.alert_rank !== "A"}
                  className="select select-bordered"
                >
                  {statuses.map((status) => (
                    <option key={status.value} value={status.value}>
                      {status.value}
                    </option>
                  ))}
                </select>
                <Button
                  name={"更新"}
                  event={() => handleUpdateStatus(index, item)}
                  disabled={item.alert_rank !== "A"}
                  className="btn btn-outline ml-auto"
                ></Button>
              </div>
            </div>
          ))
        ) : (
          <div className="py-4">該当データなし</div>
        )}
      </div>
      <ErrorModal
        open={showErrorModal}
        setOpen={setShowErrorModal}
        error={error}
        setError={setError}
      />
    </Authenticator>
  );
}

export default App;
