import React from "react";

/** エラー発生時のモーダルコンポーネント */
const ErrorModal = (props) => {
  /** モーダルを閉じる */
  const closeModal = () => {
    props.setError(null);
    props.setOpen(false);
  };

  /** 設定されたメッセージに改行コードがあった場合に改行する */
  const MultiLineBody = ({ body }) => {
    const texts = body.split("\n").map((item, index) => {
      return (
        <React.Fragment key={index}>
          {item}
          <br />
        </React.Fragment>
      );
    });
    return <div>{texts}</div>;
  };

  return (
    <>
      {props.open ? (
        <dialog
          id="my_modal_1"
          className="modal"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000,
          }}
          open={props.open}
        >
          <form method="dialog" className="modal-box">
            <h3 className="font-bold text-lg mb-5">
              <MultiLineBody
                body={
                  "エラーが発生しました。\n時間をおいて再度お試しください。"
                }
              />
            </h3>
            <div className="modal-action">
              <button className="btn" onClick={closeModal}>
                閉じる
              </button>
            </div>
          </form>
        </dialog>
      ) : (
        <></>
      )}
    </>
  );
};

export default ErrorModal;
