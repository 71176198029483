import AWS from "aws-sdk";

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const dynamoDB = new AWS.DynamoDB.DocumentClient();
const getAlertDataApi =
  "https://f2ntj9h5rl.execute-api.ap-northeast-1.amazonaws.com/prod/alert/";
const updateAlertDataApi =
  "https://f2ntj9h5rl.execute-api.ap-northeast-1.amazonaws.com/prod/alert/status";

export { dynamoDB, getAlertDataApi, updateAlertDataApi };
