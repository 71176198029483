import { getAlertDataApi, updateAlertDataApi } from "./aws-config";

/* APIGateway経由でDynamoDBのデータを取得する */
export const getAlertData = async (params) => {
  const url = new URL(getAlertDataApi);
  Object.keys(params).forEach((key) => {
    url.searchParams.append(key, params[key]);
  });
  try {
    const response = await fetch(url);
    if (!response) {
      throw new Error("No response");
    } else if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.message || "HTTP error. status: " + response.statusText
      );
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

/* APIGateway経由でDynamoDBのデータを更新する */
export const updateAlertData = async (item, status) => {
  const url = new URL(updateAlertDataApi);
  const params = {
    id: item.id,
    mail_received_date: item.mail_received_date,
    status: status,
  };
  const body = JSON.stringify(params);
  try {
    const response = await fetch(url, {
      method: "POST",
      body: body,
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response) {
      throw new Error("No response.");
    } else if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.message || "HTTP error. status: " + response.statusText
      );
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updateing data:", error);
    throw error;
  }
};
